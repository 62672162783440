.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Nunito Regular'), local('Nunito-Regular'), 
   url(https://fonts.gstatic.com/s/nunito/v11/XRXV3I6Li01BKofINeaBTMnFcQ.woff2) 
   format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, 
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, 
    U+2215, U+FEFF, U+FFFD;
}

.page {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  
  .inner {
    justify-content: center;
    
    .page-title {
      padding-top: 180px;

      .line-wrap {
        overflow: hidden;
      }

      .line1 {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 20px;
        color: #000;
      }

      .line2 {
        font-size: 18px;
        color: #000;
      }
    }

    .register-step {
      .page-title {
        padding-top: 20px;
      }

      &.step-2 {
        .line1 {
          width: 285px;
          margin: 0 auto;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 400ms;
  transition-delay: 600ms;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.error-enter {
  opacity: 0;
  transition: opacity 1500ms;
}
.error-enter-active {
  opacity: 1;
}
.error-exit {
  opacity: 1;
  transition: opacity 1500ms;
}
.error-exit-active {
  opacity: 0;
}

#monday-start-time, 
#tuesday-start-time,
#wednesday-start-time,
#thursday-start-time,
#friday-start-time,
#saturday-start-time,
#sunday-start-time,
#break-start-time,
#monday-end-time, 
#tuesday-end-time,
#wednesday-end-time,
#thursday-end-time,
#friday-end-time,
#saturday-end-time,
#sunday-end-time,
#break-end-time {
  padding-top: 10px;
  padding-bottom: 10px;
}

.MuiOutlinedInput-adornedEnd {
  padding-right: 0px !important;
}

.makeStyles-opening-17 {
  .MuiFormHelperText-root.Mui-error {
    display: none;
  }
}

@media screen and (max-width: 40em) {
  .responsiveTable tbody tr {
    border: none !important;
  }
}

@media screen and (max-width: 40em) {
  .responsiveTable td.pivoted:first-child {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 40em) {
  .responsiveTable td.makeStyles-colAdd-14.pivoted .tdBefore {
    display: none !important;
  }
}

.MuiSelect-select.MuiSelect-select {
  padding: 10px 14px !important;
}

#calendar-wrapper {
  display: block;

  #calendar, #events {
    width: 100%;
    background-color: #fff;
  }

  #events {
    margin-left: 20px;
    margin-top: 60px;
  }

  .fc-daygrid-block-event .fc-event-time, .fc-daygrid-block-event .fc-event-title {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .fc-daygrid-day-number:hover, .fc-event-title:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 959px) {
  #calendar-wrapper {
    display: block !important;
  }

  #calendar, #events {
    width: 100% !important;
  }

  .fc .fc-toolbar {
    display: block !important;
  }

  #events {
    margin-left: 0px !important;
  }
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  background-color: #9F0000;
  border: solid 1px #9F0000;
}

.fc .fc-button-primary {
  background-color: #630000 !important;
  border: none !important;

  &:active, &:focus {
    outline: none;
    box-shadow: none;
  }
}

.fc .fc-button-primary:disabled {
  background-color: #e5aeae !important;
  border: none !important;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child),
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  background-color: #630000;
  border: none;

  &:active, &:focus {
    outline: none;
    box-shadow: none;
  }
}

.fc .fc-button-primary:not(:disabled):active:focus, .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: none !important;
}

.MuiDialog-paper {
  border-radius: 45px !important;
  padding: 44px !important;
}

@media (max-width: 663.95px) {
  .MuiDialog-paperWidthSm.MuiDialog-paperScrollBody {
      max-width: 90% !important;
      margin: auto;
      padding: 20px 0px !important;
  }
}

#confirm-dialog {
  .MuiDialog-paper {
    border-radius: 3px !important;
  }
  
  #confirm-buttons-container {
    justify-content: center;

    button {
      color: #9F0000;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

div#service-info-map > div:nth-child(2) {
  border-radius: 40px;
}

#service-info-current-calendar, #service-info-next-calendar {
  margin-left: 55px;
  margin-right: 10px;
  margin-top: 40px;

  .o_day-picker .i_day-picker-row, .o_day-picker .i_day-picker-row > div {
    cursor: not-allowed;
    pointer-events: none;
  }

  .e_day-picker-arrow-container {
    display: none;
  }

  .o_day-picker .i_day-picker-title {
    left: 0px;
    padding-left: 0px;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
    text-align: left;
  }

  .o_day-picker .i_day-picker-header {
    background: #fff;
    color: #ccc;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: -22px;
  }

  .o_day-picker .i_day-picker-row {
    margin-bottom: 7px;
    margin-top: 7px;
  }

  .o_day-picker .i_day-picker-row, .o_day-picker .i_day-picker-row > div {
    border: none;
    border-width: 0px;
  }

  .o_day-picker .i_day-picker-row [class*="-day"], .o_day-picker .i_day-picker-row [class*="-day"] {
    background: #fff !important;
    font-size: 16px;
    text-align: center;
    padding-top: 5px;
    min-height: 20px;
    pointer-events: none !important;
  }

  .o_day-picker .i_day-picker-row .o_selected-current-channel-day {
    background: #9f0000 !important;
    color: #fff !important;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    font-size: 16px !important;
    text-align: center !important;
    padding-top: 8px !important;
    padding-bottom: 8px;
    min-height: 36px !important;
  }

  .o_day-picker .i_day-picker-body {
    margin-left: -22px;
  }
}

#service-info-edit-current-calendar, #service-info-edit-next-calendar {
  margin-left: 55px;
  margin-right: 10px;
  margin-top: 40px;

  .o_day-picker .i_day-picker-row, .o_day-picker .i_day-picker-row > div {
    cursor: not-allowed;
    pointer-events: none;
  }


  .e_day-picker-buttons {
    margin-left: -10px;
  }

  .i_day-picker-arrow-left {
    border-right: 5px solid #9F0000;
  }

  .i_day-picker-arrow-right {
    border-left: 5px solid #9F0000;
    margin-right: 15px;
  }

  .o_day-picker .i_day-picker-title {
    left: 0px;
    padding-left: 0px;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
  }

  .o_day-picker .i_day-picker-header {
    background: #fff;
    color: #ccc;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: -22px;
  }

  .o_day-picker .i_day-picker-row {
    margin-bottom: 7px;
    margin-top: 7px;
  }

  .o_day-picker .i_day-picker-row, .o_day-picker .i_day-picker-row > div {
    border: none;
    border-width: 0px;
  }

  .o_day-picker .i_day-picker-row [class*="-day"], .o_day-picker .i_day-picker-row [class*="-day"] {
    background: #fff !important;
    font-size: 16px;
    text-align: center;
    padding-top: 5px;
    min-height: 20px;
    pointer-events: none !important;
  }

  .o_day-picker .i_day-picker-row .o_selected-current-channel-day {
    background: #9f0000 !important;
    color: #fff !important;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    font-size: 16px !important;
    text-align: center !important;
    padding-top: 8px !important;
    padding-bottom: 8px;
    min-height: 36px !important;
  }

  .o_day-picker .i_day-picker-body {
    margin-left: -22px;
  }
}

#service-info-edit-current-calendar, #service-info-edit-next-calendar {
  .o_day-picker .i_day-picker-row, .o_day-picker .i_day-picker-row > div {
    cursor: pointer !important;
    pointer-events: none !important;
  }

  .o_day-picker .i_day-picker-row [class*="-day"], .o_day-picker .i_day-picker-row [class*="-day"] {
    pointer-events: initial !important;
  }
}

@media (max-width: 995px) {
  #service-info-current-calendar, #service-info-next-calendar, 
  #service-info-edit-current-calendar, #service-info-edit-next-calendar {
    margin-left: 20px;
  }

  .o_selected-current-channel-day, .o_selected-current-channel-day {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  #service-info-current-calendar .o_day-picker .i_day-picker-title, #service-info-next-calendar .o_day-picker .i_day-picker-title, 
  #service-info-edit-current-calendar .o_day-picker .i_day-picker-title, #service-info-edit-next-calendar .o_day-picker .i_day-picker-title {
    margin-top: -20px;
    margin-left: -12px;
  }

  .o_day-picker .i_day-picker-title {
    margin-left: -25px !important;
  }
}

#step-opening {
  .MuiFormHelperText-root.Mui-error {
    display: none !important;
  }
}

#friday {
  .MuiFormControlLabel-root {
    margin-left: -32px;
  }
}

#sunday {
  .MuiFormControlLabel-root {
    margin-left: -20px;
  }
}

#monday, #tuesday {
  .MuiFormControlLabel-root {
    margin-left: -15px;
  }
}

.rating-star {
  width: 16px;
  height:16px;
}

.bell {
  color: #9F0000;
  margin-right: 14px;
  margin-top: -40px;
}

.Countdown {
  margin: 0px auto;
}

.Countdown-col {
  display: inline-block;
}

.Countdown-col-element {
  display: inline-block;
  margin: 0 3px;
  display: flex;
  flex-direction: column;
}

.Countdown-col-element strong {
  font-size: 16px;
}

@media screen and (max-width: 959px) {
  #services-maincontent {
    width: 100% !important;
  }
}

.fc-timegrid-event {
  background-color: #9F0000 !important;
  color: #FFFFFF !important;
  border: none !important;
}

.fc-event-time:hover {
  cursor: pointer;
}

.fc .fc-bg-event {
  background-color: #a7a7a7 !important;
}

.page .inner .page-title .line1 { 
  margin-top: 10px;
} 

.page .inner .register-step.step-2 .line1 { 
  margin-top: 10px;
  line-height: 1em;
}

#monday-start-time-helper-text, #tuesday-start-time-helper-text, #wednesday-start-time-helper-text, #thursday-start-time-helper-text, #friday-start-time-helper-text, #saturday-start-time-helper-text, #sunday-start-time-helper-text, #break-start-time-helper-text,
#monday-end-time-helper-text, #tuesday-end-time-helper-text, #wednesday-end-time-helper-text, #thursday-end-time-helper-text, #friday-end-time-helper-text, #saturday-end-time-helper-text, #sunday-end-time-helper-text, #break-end-time-helper-text {
  display: none;
}

#break-start-container .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline,
#break-end-container .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0,0,0,0.23) !important;
}

#dashboard-view-container {
  display: flex;
  margin-bottom: 15px;
  // position: absolute;
  // margin-top: -25px;
  // margin-left: 250px;

  .view-container {
    border: solid 1px rgba(0,0,0,0.25);
    padding: 4px 13px;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
    }

    &.card {
      border-radius: 0px 3px 3px 0px;
      border-left: none;
    }

    &.calendar {
      border-radius: 3px 0px 0px 3px;
    }
  }

  #calendar-view, #card-view {
    &:hover {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 959px) {
  #dashboard-view-container {
    margin-top: 25px;
    margin-left: 0px;
  }

  #events {
      margin-top: 115px;
  }

  #calendar-wrapper {
    margin-top: 80px;
  }
}

#block-end-time-wrapper .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23);
}

#block-end-time-wrapper .MuiFormHelperText-root.Mui-error {
  display: none;
}

#terms-container {
  height: 80vh;
  overflow-y: scroll;
}

#terms-container::-webkit-scrollbar {
  display: none;
}

.wash-center-card, .service-card {
  display: flex;
  flex-direction: column;
  background: #9F0000;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  transition: transform 100ms ease-in-out;
  width: 100%;
  min-height: 100px;

  span:last-of-type {
      color: #fff;
      padding: 0.5rem;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    position: relative;
    background: #fff;
    transform: scale(1.125);
    z-index: 1000;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    cursor: pointer;
    
    span:last-of-type {
      color: #9F0000;
      padding: 0.5rem;
    }
  }
}

#tab-container {
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.full-width-row {
  div {
    width: 100%;
  }
}

#acw-filter.MuiToggleButton-root.Mui-selected {
  background-color: #30acdc !important;
  color: #fff !important;
}

#ecw-filter.MuiToggleButton-root.Mui-selected {
  background-color: #f08010 !important;
  color: #fff !important;
}

#mad-filter.MuiToggleButton-root.Mui-selected {
  background-color: #c52835 !important;
  color: #fff !important;
}

#pac-filter.MuiToggleButton-root.Mui-selected {
  background-color: #1b1b1b !important;
  color: #fff !important;
}

#smc-filter.MuiToggleButton-root.Mui-selected {
  background-color: #ff0404 !important;
  color: #fff !important;
}

#smr-filter.MuiToggleButton-root.Mui-selected {
  background-color: #0884f4 !important;
  color: #fff !important;
}

#tdl-filter.MuiToggleButton-root.Mui-selected {
  background-color: #086c94 !important;
  color: #fff !important;
}

.fc-event-main p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

form {
  #notes, #discount, #name, #email, #phone, #area, #building, #road, #block, #locationlink, #amount {
    width: 580px;
  }

  #service select, #washcenter select {
    width: 532px;
    margin-bottom: 20px;
  }
}

.rdt_TableHead {
  z-index: initial !important;
}

.PhoneInput {
  border-bottom: solid 1px #c7c7c7;
  padding-bottom: 10px;
}

.PhoneInputInput {
  border: none;
}

.PhoneInputInput:focus-within {
  outline: none !important;
}

#editbooking {
  width: 80%;
}

#editbooking header {
  background-color: #fff;
  box-shadow: none;
}

#editbooking .MuiTabScrollButton-root {
  width: 24px;
}

#editbooking #editbookingheader {
  margin-left: 24px;
  margin-right: 24px;
}

.react-daterange-picker__wrapper {
  padding: 4px;
  border-radius: 5px;
  border-color: hsl(0, 0%, 80%);
}